import axios from 'axios';
import { isNil } from 'lodash';

import { DESIGN_API_URL } from '../../lib/environment';

import { escapeRegExp } from '../utils';

const tags = () => {
	let tagsCancelToken = axios.CancelToken.source();

	return {
		cancel: () => {
			tagsCancelToken.cancel('Operation canceled by the user.');
			tagsCancelToken = axios.CancelToken.source();
		},

		create: (data) => {
			return new Promise((resolve, reject) => {
				const controller = new AbortController();

				let timeout = setTimeout(() => {
					controller.abort();
				}, 180000);

				axios({
					url: `${DESIGN_API_URL}/tags`,
					method: 'POST',
					cache: 'no-cache',
					data: data,
					signal: controller.signal,
					cancelToken: tagsCancelToken.token,
				})
					.then((resp) => {
						clearTimeout(timeout);

						resolve(resp.data.result);
					})
					.catch((err) => {
						clearTimeout(timeout);

						reject(err);
					});
			});
		},

		createCategory: (tagObject) => {
			return new Promise((resolve, reject) => {
				const controller = new AbortController();

				let timeout = setTimeout(() => {
					controller.abort();
				}, 180000);

				axios({
					url: `${DESIGN_API_URL}/tags$Category`,
					method: 'POST',
					cache: 'no-cache',
					data: tagObject,
					signal: controller.signal,
				})
					.then((resp) => {
						clearTimeout(timeout);

						resolve(resp.data.result);
					})
					.catch((err) => {
						clearTimeout(timeout);

						reject(err);
					});
			});
		},

		createGeneralTag: (tagObject) => {
			return new Promise((resolve, reject) => {
				const controller = new AbortController();

				let timeout = setTimeout(() => {
					controller.abort();
				}, 180000);

				axios({
					url: `${DESIGN_API_URL}/tags$General`,
					method: 'POST',
					cache: 'no-cache',
					data: tagObject,
					signal: controller.signal,
				})
					.then((resp) => {
						clearTimeout(timeout);

						resolve(resp.data.result);
					})
					.catch((err) => {
						clearTimeout(timeout);

						reject(err);
					});
			});
		},

		read: (limit, offset) => {
			return new Promise((resolve, reject) => {
				limit = limit || 24;
				offset = offset || 0;

				const controller = new AbortController();

				let timeout = setTimeout(() => {
					controller.abort();
				}, 180000);

				axios({
					url: `${DESIGN_API_URL}/tags?limit=${limit}&offset=${offset}`,
					method: 'GET',
					cache: 'no-cache',
					signal: controller.signal,
					cancelToken: tagsCancelToken.token,
				})
					.then((resp) => {
						clearTimeout(timeout);

						resolve(resp.data);
					})
					.catch((err) => {
						clearTimeout(timeout);

						reject(err);
					});
			});
		},

		readGeneral: (queryVals) => {
			return new Promise((resolve, reject) => {
				let { ownerId, searchTerm, includeHidden, limit, sort } = queryVals;

				let queryParams = {};

				const controller = new AbortController();

				let timeout = setTimeout(() => {
					controller.abort();
				}, 180000);

				if (ownerId) {
					queryParams = {
						$and: [
							+ownerId === 0 // 0 is a special case for the system owner
								? {
										'tagSet.owner.idNumber': +ownerId, // idNumber is a number, _id is a string
									}
								: {
										'tagSet.owner._id': ownerId,
									},
						],
					};
				}

				// search: (params?.search ? params.search.split(' ').map((e) => escapeRegExp(e)).join('.*') : ""),
				// "name":{"$regex":"Listing.*","$options":"i"}}
				if (!isNil(searchTerm) && searchTerm !== '') {
					queryParams.name = {
						$regex: `${
							searchTerm
								? searchTerm
										?.split(' ')
										.map((e) => escapeRegExp(e))
										.join('.*')
								: ''
						}`,
						$options: 'i',
					};
				}

				if (includeHidden === false) {
					queryParams = {
						$and: [
							...(queryParams['$and'] ?? []),
							{ isHidden: false }
						]
					};
				}

				axios({
					url: `${DESIGN_API_URL}/tags$General?q=${JSON.stringify(queryParams)}
									&limit=${limit || null}
									&sort=${JSON.stringify(sort) || '{"dtUpd":"asc"}'}`,
					method: 'GET',
					cache: 'no-cache',
					signal: controller.signal,
				})
					.then((resp) => {
						clearTimeout(timeout);

						resolve(resp.data);
					})
					.catch((err) => {
						clearTimeout(timeout);

						reject(err);
					});
			});
		},

		readCategories: (queryVals) => {
			return new Promise((resolve, reject) => {
				let { ownerId, searchTerm, isHidden, limit, sort } = queryVals;

				let queryParams = {};

				const controller = new AbortController();

				let timeout = setTimeout(() => {
					controller.abort();
				}, 180000);

				if (ownerId && ownerId !== 'null') {
					queryParams = {
						$and: [
							Number(ownerId) === 0 // 0 is a special case for the system owner
								? {
										'tagSet.owner.idNumber': +ownerId, // idNumber is a number, _id is a string
									}
								: {
										'tagSet.owner._id': ownerId,
									},
						],
					};
				}

				// search: (params?.search ? params.search.split(' ').map((e) => escapeRegExp(e)).join('.*') : ""),
				// "name":{"$regex":"Listing.*","$options":"i"}}
				if (!isNil(searchTerm) && searchTerm !== '') {
					queryParams.name = {
						$regex: `${
							searchTerm
								? searchTerm
										?.split(' ')
										.map((e) => escapeRegExp(e))
										.join('.*')
								: ''
						}`,
						$options: 'i',
					};
				}

				if (isHidden === false) {
					queryParams['$and'] ? queryParams['$and'].push({ isHidden: false }) : queryParams.isHidden = false;
				}

				axios({
					url: `${DESIGN_API_URL}/tags$Category?q=${JSON.stringify(queryParams)}
									&limit=${limit || null}
									&sort=${JSON.stringify(sort) || '{"dtUpd":"desc"}'}`,
					method: 'GET',
					cache: 'no-cache',
					signal: controller.signal,
					cancelToken: tagsCancelToken.token,
				})
					.then((resp) => {
						clearTimeout(timeout);

						resolve(resp.data);
					})
					.catch((err) => {
						clearTimeout(timeout);

						reject(err);
					});
			});
		},

		updateCategory: (categoryId, tagObject) => {
			return new Promise((resolve, reject) => {
				const controller = new AbortController();

				let timeout = setTimeout(() => {
					controller.abort();
				}, 180000);

				axios({
					url: `${DESIGN_API_URL}/tags$Category/${categoryId}`,
					method: 'PATCH',
					cache: 'no-cache',
					data: tagObject,
					signal: controller.signal,
				})
					.then((resp) => {
						clearTimeout(timeout);

						resolve(resp.data.result);
					})
					.catch((err) => {
						clearTimeout(timeout);

						reject(err);
					});
			});
		},

		deleteCategory: (tagId) => {
			return new Promise((resolve, reject) => {
				const controller = new AbortController();

				let timeout = setTimeout(() => {
					controller.abort();
				}, 180000);

				axios({
					url: `${DESIGN_API_URL}/tags$Category/${tagId}`,
					method: 'DELETE',
					cache: 'no-cache',
					signal: controller.signal,
				})
					.then((resp) => {
						clearTimeout(timeout);

						resolve(resp.data.result);
					})
					.catch((err) => {
						clearTimeout(timeout);

						reject(err);
					});
			});
		},

		getCategoriesByOwner: (ownerId) => {
			return new Promise((resolve, reject) => {
				const controller = new AbortController();

				let timeout = setTimeout(() => {
					controller.abort();
				}, 180000);

				/**
				 * API HACK: Provide the param 'owner' for a meta.uiSupport containing ownerOptions;
				 * Omit 'owner' for a meta.uiSupport containing existingTags
				 * (for comparison of user input against existing tag names).
				 * Todd apologizes for this nastiness...
				 */
				axios({
					url: `${DESIGN_API_URL}/tags$Category/NEW?owner=${ownerId}`,
					method: 'GET',
					cache: 'no-cache',
					signal: controller.signal,
					cancelToken: tagsCancelToken.token,
				})
					.then((resp) => {
						clearTimeout(timeout);

						resolve(resp.data);
					})
					.catch((err) => {
						clearTimeout(timeout);

						reject(err);
					});
			});
		},
	};
};

const singleton = tags();
Object.freeze(singleton);

export default singleton;
